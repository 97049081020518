.empty-data-wrapper {
	width: 100%;
	text-align: center;
	padding: 20px 0px;
	color: #252525;
	font-size: 16px;
}

.danger {
	color: crimson;
}
