@import 'assets/styles/_variable.scss';

.dashboard-container {
	overflow: auto;
	.matfoxx-bot {
		right: 0;
		bottom: 0;
	}
}

.dashboard-section {
	height: calc(100vh - 75px);
	padding: 0px 24px 0px 24px;
}
.cards-container {
	width: 100%;
	padding-bottom: 30px;

	&.customize-mode__container {
		padding: 19px 12px 12px 12px;
		background-color: var(--greyscale-25);
		border-radius: 8px;
		margin: 5px 0;
	}
}
.sortable__wrapper {
	display: grid;
	grid-gap: 16px;
	grid-template-columns: 33.33% 33.33% repeat(auto-fill, minmax(0, 16.665%));
	grid-template-rows: repeat(6, 59.66px);
	min-height: 0; /* NEW */
	min-width: 0; /* NEW; needed for Firefox */
	grid-template-areas:
		'grid--0 grid--1 grid--2 grid--4'
		'grid--0 grid--1 grid--2 grid--4'
		'grid--0 grid--1 grid--2 grid--5'
		'grid--0 grid--1 grid--3 grid--5'
		'grid--0 grid--1 grid--3 grid--6'
		'grid--0 grid--1 grid--3 grid--6';
}

.favourite-item__wrapper {
	background-color: $white-color;
	box-sizing: border-box;
	min-width: 0; /* NEW; needed for Firefox */
	border-radius: 8px;
	position: relative;

	.chart-wrapper {
		height: calc(100% - 34px);
		overflow-y: auto;
		overflow-x: hidden;
	}
	.cost-modal-card {
		background-color: white;
		border-radius: 8px;
		height: 100%;
		.cost-modal-card-header__wrapper {
			border-bottom: 1px solid var(--greyscale-10);
			.cost-modal-icon__wrapper {
				width: 34px;
				height: 34px;
				background-color: var(--primary-color);
				border-top-left-radius: 8px;
				border-bottom-right-radius: 8px;
			}

			.unit__wrapper {
				width: calc(100% - 34px);
				padding: 0 8px;

				.active-card-circle {
					width: 15px;
					height: 15px;
					border-radius: 8px;
				}
				.cross-icon__wrapper {
					.cross-icon {
						height: 10px;
						width: 10px;
					}
				}
			}
		}

		.cost-modal-card__wrapper {
			width: 100%;
			height: calc(100% - 67px);
			display: flex;
			justify-content: center;
			align-items: center;
			box-sizing: border-box;
			&.cost-modal-chart__wrapper {
				padding: 0 16px;
			}

			.card-items {
				height: 100%;
				position: relative;
				box-sizing: border-box;
			}

			canvas {
				width: 100% !important;
				height: 100% !important;
			}

			.my-tooltip {
				filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2));
			}

			.table-responsive {
				width: 100%;
				height: 100%;
				table {
					margin: 0 !important;
					border: 1px solid var(--greyscale-20);
					thead {
						tr {
							border-bottom: 1px solid var(--greyscale-20);
							background-color: var(--greyscale-10);
							th {
								padding: 4px 16px;
								border-right: 1px solid var(--greyscale-20);
							}
						}
					}
					tbody {
						tr {
							border-bottom: 1px solid var(--greyscale-20);
							&:hover {
								background-color: var(--greyscale-0);
							}
							td {
								padding: 3px 16px;
								border-right: 1px solid var(--greyscale-20);
							}
						}
					}
				}
			}
		}

		.bottom-panel__wrapper {
			height: 32px;
			.calender__wrapper {
				width: calc(100% - 32px);
				padding-left: 16px;
			}
			.bottom-modal-icon__wrapper {
				width: 32px;
				height: 32px;
				background-color: var(--accent-yellow-color);
				border-bottom-right-radius: 8px;
				border-top-left-radius: 8px;

				svg {
					width: 20px;
					height: 20px;
				}
			}
		}
	}
}

.sortable-ghost {
	opacity: 0.5;
}

.favourite-item__wrapper:nth-child(1) {
	grid-area: grid--0;
}

.favourite-item__wrapper:nth-child(2) {
	grid-area: grid--1;
}
.favourite-item__wrapper:nth-child(3) {
	grid-area: grid--2;
}
.favourite-item__wrapper:nth-child(4) {
	grid-area: grid--3;
}
.favourite-item__wrapper:nth-child(5) {
	grid-area: grid--4;
}

.favourite-item__wrapper:nth-child(6) {
	grid-area: grid--5;
}

.favourite-item__wrapper:nth-child(7) {
	grid-area: grid--6;
}

.favourite-item__wrapper.large__card--item {
	width: 344px;
	height: 300px;
}

.card-header__wrapper {
	opacity: 1;
	position: relative;

	.header-info__wrapper {
		width: calc(100% - 34px);
		.card-header {
			width: calc(100% - 44px);
		}
	}
	.dashboard-icon__wrapper {
		height: 34px;
		width: 34px;
		/* box-sizing: border-box; */
		background: var(--primary-color);
		display: flex;
		justify-content: center;
		align-items: center;
		border-top-left-radius: 8px;
		border-bottom-right-radius: 8px;
		cursor: grabbing;
	}

	.cross-icon__wrapper {
		padding-right: 12px;
		.cross-icon {
			height: 10px;
			width: 10px;
		}
	}
}

.card-items {
	height: calc(100% - 40px);
	position: relative;
	box-sizing: border-box;

	.items {
		height: 80px;
		padding: 8px 12px;
	}

	.card-content {
		height: calc(100% - 32px);
		overflow: auto;
	}

	.medium-news-card__wrapper {
		padding: 10px 12px;
		display: flex !important;
		justify-content: flex-end;
		align-items: center;
		border-bottom: 1px solid var(--greyscale-10);
		height: 74px;
		box-sizing: border-box;

		.news-image {
			width: 48px;
			height: 48px;
			margin-right: 8px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 4px;
			}
		}

		.news-data__wrapper {
			width: calc(100% - 56px);
			.news-data {
				margin-right: 8px;
				width: calc(100% - 28px);
			}
			.star-icon__wrapper {
				.star-icon {
					width: 20px;
					height: 20px;
				}

				.unfilled-star-icon {
					fill: var(--accent-yellow-color);
				}
			}
		}
	}
	.tender-card__wrapper {
		.news-data__wrapper {
			width: 100%;
		}
	}
	.swiper {
		width: 100%;
		height: calc(100% - 32px);

		.swiper-wrapper {
			height: 100%;

			.swiper-slide {
				width: 100%;

				.news-card__wrapper {
					padding: 0px 12px 0px 12px;
					.news-image {
						height: 142px;
						margin-bottom: 16px;
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
							border-radius: 8px;
						}
					}

					.news-data__wrapper {
						.news-data {
							width: calc(100% - 56px);
						}
						.star-icon__wrapper {
							.star-icon {
								width: 20px;
								height: 20px;
							}

							.unfilled-star-icon {
								fill: var(--accent-yellow-color);
							}
						}
					}
					.news-article-date {
						p {
							font-size: 16px;
							font-weight: 400;
							line-height: 22px;
						}
					}
				}
			}
		}
	}
}
.card-bottom {
	height: 32px;
	border-top: 1px solid var(--greyscale-10);
	padding: 0px 0px 0px 12px;

	.swiper-arrow__wrapper {
		width: 100px;
		height: 20px;

		.swiper-custom-pagination {
			transform: translateX(0%) !important;

			.swiper-pagination-bullet {
				width: 8px !important;
				height: 4px !important;
				background-color: var(--greyscale-30);
				margin-left: 0 !important;
				border-radius: 13px;

				&.swiper-pagination-bullet-active {
					background-color: var(--primary-color) !important;
					margin: 0px 4px 0px 0px;
					margin-left: 0 !important;
					transform: scale(1);
				}
				&:not(.swiper-pagination-bullet-active-main) {
					background-color: var(--greyscale-30);
					transform: scale(0) !important;
				}
			}
		}
	}

	.slick-disabled {
		pointer-events: none;
		fill: var(--greyscale-30);
	}

	.card-change-icon__wrapper {
		.card-change__button {
			background-color: var(--accent-yellow-color);
			border-top-right-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
}

.procedure-modal {
	.modal-dialog {
		width: 757px !important;
	}
}

.dashboard-model {
	.modal-dialog {
		width: 966px !important;
	}
}

.procedure-modal,
.dashboard-model {
	.procedure-card__wrapper {
		width: 100%;
		.card__wrapper {
			width: 33.33%;
			border: 1px solid var(--greyscale-20);
			border-radius: 8px;
			padding: 20px;
			margin-right: 16px;

			&:last-child {
				margin-right: 0px;
			}

			&.active {
				border: 2px solid var(--accent-yellow-color);
				padding: 19px;
			}

			.card-icon__wrapper {
				width: 48px;
				height: 48px;
				background: var(--greyscale-10);
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 8px;

				.robot-icon {
					width: 21px;
					height: 26px;
					fill: var(--blue-color);
				}
			}
		}
	}
}

.announcement-main {
	overflow: auto;
	max-height: calc(100vh - 210px);
}

.dashboard-chart-hover-tooltip {
	min-width: 500px;
	min-height: 70px;
	position: absolute;
	background-color: var(--greyscale-0);
	padding: 7px 12px;
	right: 0px;
	border-radius: 25px;
	box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.25);
	z-index: 999;
	box-sizing: border-box;
	z-index: 100000;
	&.left-align {
		left: 0;
		right: unset;
	}

	.chart-box--wrapper {
		width: 6px;
		height: 9px;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
		margin-right: 7px;
		margin-top: 4px;
	}
	.chart-hover-tooltip-name__wrapper {
		.chart-name {
			width: max-content;
		}
	}

	.indicator__wrapper {
		margin-left: 18px;
		.indicator-name__wrapper {
			margin-right: 10px;
			.chart-box--wrapper {
				margin-top: 0px !important;
			}
			.indicator-name {
				width: max-content;
			}
		}
	}
}

@keyframes inAnimation {
	0% {
		opacity: 0;
		visibility: hidden;
	}
	100% {
		opacity: 1;
		visibility: visible;
	}
}
