@import './variable';

*,
*::after,
*::before {
	box-sizing: inherit;
}

* {
	font-family: 'Outfit';
	outline: none;
}

body {
	@include margin(0);
	@include padding(0);
	background-color: var(--primary-color);
	color: var(--primary-color);
}

::-webkit-scrollbar-track {
	background-color: var(--greyscale-30) !important;
}

::-webkit-scrollbar {
	width: 6px !important;
	height: 3px !important;
}

::-webkit-scrollbar-thumb {
	background-color: var(--primary-color);
	border-radius: 20px;
	border: 6px solid var(--primary-color);
	background-clip: content-box;
}

button {
	cursor: pointer;
	outline: none;
}

a {
	text-decoration: none;
}

ul {
	@include padding(0);
}

ul {
	list-style: none;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	@include margin(0);
}

input[type='number'] {
	-moz-appearance: textfield;
}

textarea {
	resize: vertical;

	&::-webkit-scrollbar {
		width: 5px;
		border-radius: 5px;
		box-shadow: inset 0 0 4px -0.5px rgba(0, 0, 0, 0.2);
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 5px;
		background-color: rgba(0, 0, 0, 0.5);
	}
}

button::-moz-focus-inner {
	border: 0;
}

a:hover,
a:active,
a:focus {
	outline: 0;
}

input {
	-webkit-appearance: none;
	user-select: text;
}

pre {
	white-space: pre-wrap;
	white-space: -moz-pre-wrap;
	white-space: -pre-wrap;
	white-space: -o-pre-wrap;
	word-wrap: break-word;
	font-size: 12px;
	line-height: 20px;
	background-color: $white-color;
	border: none;
	color: var(--primary-color);
	font-family: monospace;
}

iframe {
	border: none;
}

h1 {
	font-size: 3rem;

	&.size--small {
		font-size: 2.125rem;
	}
}

h2 {
	font-size: 2.4375rem;
	color: var(--primary-color);

	&.size--small {
		font-size: 2.0625rem;
	}
}

h3 {
	font-size: 2.0625rem;

	&.size--small {
		font-size: 1.75rem;
	}
}

h4 {
	font-size: 2.0625rem;

	&.size--small {
		font-size: 1.4375rem;
	}
}

h5 {
	font-size: 1.4375rem;

	&.size--small {
		font-size: 1.1875rem;
	}
}

h6 {
	font-size: 1.1875rem;

	&.size--small {
		font-size: 1rem;
	}
}

p {
	font-size: 0.875rem;
	line-height: 17px;
}

video {
	background-color: rgba(0, 0, 0, 0.5);
}
