@import './mixin';
@import './extend';
@import './variable';
@import './fonts';
@import './base';
@import './style.scss';
@import './custom.scss';
@import './responsive';
@import './variable';

// Custom nav nav-tabs
ul.nav.nav-tabs {
	li.active {
		a {
			margin-right: 0;
			background: #fff;
			border-bottom: 1px solid #fff;
		}
	}
}

// Form Control style
form .panel .panel-body {
	.row:last-child {
		margin-bottom: 0;
	}
	> .input-checkbox:last-child,
	> .input-radio:last-child {
		margin-bottom: 0 !important;
	}
}
.row.form-horizontal {
	display: flex;
	margin-bottom: 10px;
	min-height: 54px;
	label.control-label {
		padding-top: 7px;
		font-weight: 600;
		cursor: pointer;
	}
}
.form-group,
.input-checkbox {
	label {
		font-weight: 600;
		margin-bottom: 0;
	}
}
// Text-help in form-group
.form-group {
	position: relative;
	&.float-message {
		margin-bottom: 15px !important;
		span.text-danger {
			position: absolute;
			top: -2px;
			right: 0;
			background-color: #ed5565;
			color: #fff;
			padding: 0 10px;
			border-top-left-radius: 3px;
			border-top-right-radius: 3px;
		}
	}
	&.has-error {
		label {
			color: #ed5565 !important;
		}
	}
	span.text-danger {
		display: inline-block;
		margin-top: 3px;
		font-size: 12px;
		&:first-letter {
			text-transform: uppercase;
		}
	}
}

// Loading Icon like Fb
.lds-facebook {
	display: inline-block;
	position: relative;
	width: 64px;
	height: 64px;
}
.lds-facebook div {
	display: inline-block;
	position: absolute;
	left: 6px;
	width: 13px;
	background: #fff;
	animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
	left: 6px;
	animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
	left: 26px;
	animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
	left: 45px;
	animation-delay: 0s;
}
@keyframes lds-facebook {
	0% {
		top: 6px;
		height: 51px;
	}
	50%,
	100% {
		top: 19px;
		height: 26px;
	}
}

// Custom SweetAlert
.swal-prompt {
	.swal-title {
		margin: 0;
		border-bottom: 1px solid #e9ecef;
		padding: 20px;
		font-size: 20px;
	}
	.swal-text,
	.swal-content {
		width: 100% !important;
		max-width: 100%;
		padding: 20px 30px 30px 30px;
		text-align: center;
		background: #f8fafb;
		border-bottom: 1px solid #e9ecef;
		line-height: 1.5;
		margin-top: 0 !important;
	}
	.swal-footer {
		margin: 0;
	}
}

// Custom react-table
.ReactTable {
	.rt-thead {
		&.-header {
			box-shadow: none;
			font-weight: 600;
			border-bottom: 1px solid #ddd;
			.rt-th {
				padding: 10px 10px !important;
			}
		}
		&.-filters {
			.rt-th {
				overflow: unset;
			}
		}
	}

	.rt-td {
		padding: 10px 10px !important;
	}

	.pagination-bottom {
		.-pagination {
			box-shadow: none;
			border-top: 2px solid #eee;
		}
	}
}

// Select as Filter of Table
.filter-select {
	min-height: 30px !important;
	text-align: left;
	font-size: 12px;
	.filter-select__control {
		min-height: 30px !important;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 3px;
	}
	.filter-select__value-container {
		padding: 0 !important;
	}
	.filter-select__indicators {
		.filter-select__indicator-separator,
		.filter-select__dropdown-indicator {
			display: none;
		}
		.filter-select__clear-indicator {
			padding: 0 !important;
		}
	}
	.filter-select__placeholder {
		margin-left: 5px;
	}
}

// Custom Modal
.form-modal {
	.form-group {
		margin-bottom: 5px;
	}
	.modal-body {
		padding: 20px !important;
	}
	.modal-footer {
		button,
		a {
			display: inline-block;
			margin-left: 15px !important;
		}
	}
}

// Custom React-select style
.form-group.has-error {
	.customize-select-style__control {
		border-color: #ed5565 !important;
	}
}
.customize-select-style__control {
	border-color: #e5e6e7 !important;
	border-radius: 0 !important;
	&.customize-select-style__control--is-focused {
		border-color: #1ab394 !important;
		box-shadow: none !important;
	}
}

// Style Permissions Tree
.react-checkbox-tree {
	> ol {
		> li {
			margin-bottom: 15px;
			padding: 8px 0 10px;
			background-color: #eeeeee54;
			border: 1px solid #ddd;
			border-radius: 3px;
			> span {
				> label {
					.rct-title {
						font-weight: 700;
					}
				}
			}
			> ol {
				> li {
					margin-top: 5px;
					> span {
						> label {
							.rct-title {
								font-weight: 600;
							}
						}
					}
					&:last-child {
						margin-bottom: 5px;
					}
				}
			}
		}
	}
	label {
		font-weight: 400;
		font-size: 15px;
		&:hover {
			background-color: rgba(26, 179, 147, 0.17);
		}
	}
	input {
		margin: 0 3px !important;
		position: relative;
		top: -1px;
	}
	.rct-collapse.rct-collapse-btn {
		position: relative;
		top: -1px;
	}
}

// filter-group
.filter-group__control {
	border: none !important;
	min-height: 30px !important;
	.filter-group__indicator {
		padding: 4px;
	}
}

.wizard {
	display: block;
	width: 100%;
	overflow: hidden;
	a {
		outline: 0;
	}
	ul {
		list-style: none !important;
		padding: 0;
		margin: 0;
		> li {
			display: block;
			padding: 0;
		}
	}
	> .content {
		display: block;
		margin: 5px 5px 10px 5px;
		min-height: 120px;
		overflow: hidden;
		position: relative;
		width: auto;

		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
		> .body {
			width: 100%;
			height: 100%;
			padding: 1% 0;
			ul {
				list-style: disc !important;
			}
			ul > li {
				display: list-item;
			}
			> iframe {
				border: 0 none;
				width: 100%;
				height: 100%;
			}
			input {
				display: block;
				border: 1px solid #ccc;
			}
			input[type='checkbox'] {
				display: inline-block;
			}
			input.error {
				background: rgb(251, 227, 228);
				border: 1px solid #fbc2c4;
				color: #8a1f11;
			}
			label {
				display: inline-block;
			}
			label.error {
				color: #8a1f11;
				display: inline-block;
				margin-left: 1.5em;
			}
		}
	}
	> .steps {
		position: relative;
		display: block;
		width: 100%;
		.current-info {
			position: absolute;
			left: -999em;
		}
		> ul > li {
			width: 25%;
		}
		div,
		div:hover,
		div:active {
			display: block;
			width: auto;
			margin: 0 0.5em 0.5em;
			padding: 8px;
			text-decoration: none;

			-webkit-border-radius: 5px;
			-moz-border-radius: 5px;
			border-radius: 5px;
		}

		.disabled > div,
		.disabled > div:hover,
		.disabled > div:active {
			background: #eee;
			color: #aaa;
			cursor: default;
		}

		.current > div,
		.current > div:hover,
		.current > div:active {
			background: #1ab394;
			color: #fff;
			cursor: default;
		}

		.done > div,
		.done > div:hover,
		.done > div:active {
			background: #6fd1bd;
			color: #fff;
		}

		.error > div,
		.error > div:hover,
		.error > div:active {
			background: #ed5565;
			color: #fff;
		}
	}
	> .actions {
		position: relative;
		display: block;
		text-align: right;
		width: 100%;
		> ul {
			display: inline-block;
			text-align: right;
		}
		> ul > li {
			margin: 0 0.5em;
		}
	}
	> .steps > ul > li,
	> .actions > ul > li {
		float: left;
	}
	&.vertical > .content {
		display: inline;
		float: left;
		margin: 0 2.5% 0.5em 2.5%;
		width: 65%;
	}
	&.vertical > .steps {
		display: inline;
		float: left;
		width: 30%;
		> ul > li {
			float: none;
			width: 100%;
		}
	}
	&.vertical > .actions {
		display: inline;
		float: right;
		margin: 0 2.5%;
		width: 95%;
		> ul > li {
			margin: 0 0 0 1em;
		}
	}
}

.wizard-big.wizard > .content {
	min-height: 320px;
}

.modal.in .modal-dialog {
	transform: none;
}
