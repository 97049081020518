/*
 * This combined file was created by the DataTables downloader builder:
 *   https://datatables.net/download
 *
 * To rebuild or modify this file with the latest versions of the included
 * software please visit:
 *   https://datatables.net/download/#bs/jszip-2.5.0/pdfmake-0.1.18/dt-1.10.13/b-1.2.3/b-html5-1.2.3/b-print-1.2.3
 *
 * Included libraries:
 *   JSZip 2.5.0, pdfmake 0.1.18, DataTables 1.10.13, Buttons 1.2.3, HTML5 export 1.2.3, Print view 1.2.3
 */

table.dataTable {
	clear: both;
	margin-top: 6px;
	margin-bottom: 6px;
	max-width: none;
	border-collapse: separate;
}
table.dataTable td,
table.dataTable th {
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
}
table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
	text-align: center;
}
table.dataTable.nowrap th,
table.dataTable.nowrap td {
	white-space: nowrap;
}
div.dataTables_wrapper div.dataTables_length label {
	font-weight: normal;
	text-align: left;
	white-space: nowrap;
}
div.dataTables_wrapper div.dataTables_length select {
	width: 75px;
	display: inline-block;
}
div.dataTables_wrapper div.dataTables_filter {
	text-align: right;
}
div.dataTables_wrapper div.dataTables_filter label {
	font-weight: normal;
	white-space: nowrap;
	text-align: left;
}
div.dataTables_wrapper div.dataTables_filter input {
	margin-left: 0.5em;
	display: inline-block;
	width: auto;
}
div.dataTables_wrapper div.dataTables_info {
	padding-top: 8px;
	white-space: nowrap;
}
div.dataTables_wrapper div.dataTables_paginate {
	margin: 0;
	white-space: nowrap;
	text-align: right;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
	margin: 2px 0;
	white-space: nowrap;
}
div.dataTables_wrapper div.dataTables_processing {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 200px;
	margin-left: -100px;
	margin-top: -26px;
	text-align: center;
	padding: 1em 0;
}
table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
	padding-right: 30px;
}
table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
	outline: none;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
	cursor: pointer;
	position: relative;
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
	position: absolute;
	bottom: 8px;
	right: 8px;
	display: block;
	font-family: 'Glyphicons Halflings';
	opacity: 0.5;
}
table.dataTable thead .sorting:after {
	opacity: 0.2;
	content: '\e150';
}
table.dataTable thead .sorting_asc:after {
	content: '\e155';
}
table.dataTable thead .sorting_desc:after {
	content: '\e156';
}
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
	color: #eee;
}
div.dataTables_scrollHead table.dataTable {
	margin-bottom: 0 !important;
}
div.dataTables_scrollBody table {
	border-top: none;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}
div.dataTables_scrollBody table thead .sorting:after,
div.dataTables_scrollBody table thead .sorting_asc:after,
div.dataTables_scrollBody table thead .sorting_desc:after {
	display: none;
}
div.dataTables_scrollBody table tbody tr:first-child th,
div.dataTables_scrollBody table tbody tr:first-child td {
	border-top: none;
}
div.dataTables_scrollFoot table {
	margin-top: 0 !important;
	border-top: none;
}
@media screen and (max-width: 767px) {
	div.dataTables_wrapper div.dataTables_length,
	div.dataTables_wrapper div.dataTables_filter,
	div.dataTables_wrapper div.dataTables_info,
	div.dataTables_wrapper div.dataTables_paginate {
		text-align: center;
	}
}
table.dataTable.table-condensed > thead > tr > th {
	padding-right: 20px;
}
table.dataTable.table-condensed .sorting:after,
table.dataTable.table-condensed .sorting_asc:after,
table.dataTable.table-condensed .sorting_desc:after {
	top: 6px;
	right: 6px;
}
table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
	border-left-width: 0;
}
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable td:last-child {
	border-right-width: 0;
}
table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
	border-bottom-width: 0;
}
div.dataTables_scrollHead table.table-bordered {
	border-bottom-width: 0;
}
div.table-responsive > div.dataTables_wrapper > div.row {
	margin: 0;
}
div.table-responsive > div.dataTables_wrapper > div.row > div[class^='col-']:first-child {
	padding-left: 0;
}
div.table-responsive > div.dataTables_wrapper > div.row > div[class^='col-']:last-child {
	padding-right: 0;
}

div.dt-button-info {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 400px;
	margin-top: -100px;
	margin-left: -200px;
	background-color: white;
	border: 2px solid #111;
	box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
	border-radius: 3px;
	text-align: center;
	z-index: 21;
}
div.dt-button-info h2 {
	padding: 0.5em;
	margin: 0;
	font-weight: normal;
	border-bottom: 1px solid #ddd;
	background-color: #f3f3f3;
}
div.dt-button-info > div {
	padding: 1em;
}
ul.dt-button-collection.dropdown-menu {
	display: block;
	z-index: 2002;
	-webkit-column-gap: 8px;
	-moz-column-gap: 8px;
	-ms-column-gap: 8px;
	-o-column-gap: 8px;
	column-gap: 8px;
}
ul.dt-button-collection.dropdown-menu.fixed {
	position: fixed;
	top: 50%;
	left: 50%;
	margin-left: -75px;
	border-radius: 0;
}
ul.dt-button-collection.dropdown-menu.fixed.two-column {
	margin-left: -150px;
}
ul.dt-button-collection.dropdown-menu.fixed.three-column {
	margin-left: -225px;
}
ul.dt-button-collection.dropdown-menu.fixed.four-column {
	margin-left: -300px;
}
ul.dt-button-collection.dropdown-menu > * {
	-webkit-column-break-inside: avoid;
	break-inside: avoid;
}
ul.dt-button-collection.dropdown-menu.two-column {
	width: 300px;
	padding-bottom: 1px;
	-webkit-column-count: 2;
	-moz-column-count: 2;
	-ms-column-count: 2;
	-o-column-count: 2;
	column-count: 2;
}
ul.dt-button-collection.dropdown-menu.three-column {
	width: 450px;
	padding-bottom: 1px;
	-webkit-column-count: 3;
	-moz-column-count: 3;
	-ms-column-count: 3;
	-o-column-count: 3;
	column-count: 3;
}
ul.dt-button-collection.dropdown-menu.four-column {
	width: 600px;
	padding-bottom: 1px;
	-webkit-column-count: 4;
	-moz-column-count: 4;
	-ms-column-count: 4;
	-o-column-count: 4;
	column-count: 4;
}
div.dt-button-background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2001;
}
@media screen and (max-width: 767px) {
	div.dt-buttons {
		float: none;
		width: 100%;
		text-align: center;
		margin-bottom: 0.5em;
	}
	div.dt-buttons a.btn {
		float: none;
	}
}
