.error-page__wrapper {
	height: 100vh;
	width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
	background-color: var(--primary-color);
	z-index: 99999;

	.matfoxx__wrapper {
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
