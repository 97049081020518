//------------------------------------------future table and chart section --------------------//

.future-table__container {
	overflow: auto;
	padding: 0px 20px;

	.chart {
		padding: 24px;
		border-radius: 6px;
		.chart-wrapper {
			width: 100%;
			min-height: 550px;
		}
	}
	.futuretable-section {
		.futuretable-header {
			padding: 20px 0;
		}

		.futuretable-main {
			.chart_screen {
				transition: 0.3s all ease-in-out;
			}
			.chart-additional-info--wrapper {
				.table-responsive {
					border-radius: 6px;
					table {
						margin: 0 !important;
						border: none;
						thead {
							tr {
								background-color: var(--primary-color);
								th {
									padding: 10px 40px;
								}
							}
							tr:first-child {
								border-bottom: 1px solid var(--greyscale-30);
								th:last-child {
									border-top-right-radius: 6px;
								}
							}
							tr:first-child {
								th:first-child {
									border-top-left-radius: 6px;
								}
							}
						}
						tbody {
							tr {
								background-color: var(--greyscale-0);
								&:hover {
									background-color: #fefefe;
								}
								td {
									border: 1px solid var(--greyscale-30);
									padding: 5px 18px;
								}
							}
							tr:last-child {
								border-bottom: 1px solid var(--greyscale-30);
								td:last-child {
									border-bottom-right-radius: 6px;
								}
							}
							tr:last-child {
								td:first-child {
									border-bottom-left-radius: 6px;
								}
							}
							tr:nth-child(even) {
								background-color: #f5f9fc;
								&:hover {
									background-color: #f5f9fc;
								}
							}
						}
					}
				}

				.info-table-container {
					border-radius: 6px;
					.table-responsive {
						border-radius: 6px;
						table {
							thead {
								tr {
									th {
										padding: 10px 40px;
									}
								}
							}
						}
					}
				}
				.shadow-box {
					box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
				}
				.forward-curve-container {
					height: 298px;
					.inventory-chart {
						height: calc(298px - 50px);
					}
					.forward-curve-header {
						border-radius: 6px 6px 0 0;
					}
				}
			}

			.futuretable {
				margin-right: 20px;
				border-radius: 16px;
				transition: 0.3s all ease-in-out;
				max-height: 800px;
				.future-table-container {
					background: var(--greyscale-0);
					border-radius: 8px;
					.toggle-btn-wrapper {
						padding: 10px 15px;
						.toggle-btn {
							padding: 10px;
							min-width: 120px;
							cursor: pointer;
							border: 1px solid var(--greyscale-30);
							transition: all 0.3s ease-in-out;
						}
						.material-btn {
							border-top-left-radius: 6px;
							border-bottom-left-radius: 6px;
						}
						.future-btn {
							border-top-right-radius: 6px;
							border-bottom-right-radius: 6px;
						}
						.selected {
							background-color: var(--primary-color);
							color: var(--greyscale-0);
						}
						.not-selected {
							background-color: var(--greyscale-0);
						}
					}

					.material-table__wrapper {
						.material-table_responsive {
							max-height: 738px !important;
						}
						.table-responsive {
							overflow-y: auto;
							max-height: 660px;
							table {
								table-layout: fixed;
								text-align: center;
							}
						}
					}
				}

				.futuretable-title {
					padding: 12px;
					line-height: 20px;
					margin: 0;
				}

				.export-btn-container {
					padding-bottom: 16px;
					margin: 16px 16px 0px 0px;

					.dashboard-btn {
						padding: 4px 12px;
						border-radius: 8px 0px 0px 8px !important;
					}
				}
			}

			.graph-header {
				padding: 0px 25px 0px 0px;
				border-radius: 6px;
				width: 100%;
				box-sizing: border-box;
				.chart-title__wrapper {
					min-width: calc(100% - 291px);
				}
				.color-div {
					width: 34px;
					background-color: blue;
					border-radius: 6px 0 0 6px;
				}
				.graph-title {
					width: calc(100% - 30px);
					margin: 12px 0;
					h4 {
						color: #61656a;
					}
				}

				.graph-price {
					width: 291px;
					h5 {
						text-align: left;
					}
					.delete-icon--container {
						padding: 6px;
						background-color: var(--red-light-color);
						border-radius: 6px;
						border: 1px solid var(--error-color);
					}
					.maturity-text {
						padding-left: 10px;
						padding-right: 10px;
						background-color: #ffe9cc;
					}
				}
				// }

				.chart-container {
					.chart-indicators {
						.indicator-section-1 {
							max-width: 60%;
							width: 100%;

							.tooltips {
								display: inline-block;
								width: calc(40% - 25px);
								background-color: var(--greyscale-10);
								padding: 8px;
								margin-top: 4px;
								border-radius: 4px;
								cursor: pointer;
								color: #444a51;
								border: 1px solid var(--greyscale-20);
								margin-right: 10px;

								.tooltiptext {
									visibility: hidden;
									width: 100%;
									overflow-y: auto;
									background-color: var(--greyscale-0);
									box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
									color: black;
									text-align: center;
									border-radius: 4px;
									z-index: 12;
									top: 100%;
									left: 0;

									.indicator-option-wrapper {
										.indicator-option-list {
											margin: 0;

											.option {
												color: #444a51;
												border-bottom: 1px solid #e7e7e7;
												padding: 8px 10px;
												text-align: start;

												&:hover {
													background-color: var(--greyscale-10);
													font-weight: 500;
												}
											}
											.selected {
												background-color: var(--greyscale-10);
												position: relative;

												&::before {
													content: '';
													right: 16px;
													top: 10px;
													width: 5px;
													height: 10px;
													border: solid black;
													border-width: 0 2px 2px 0;
													-webkit-transform: rotate(45deg);
													-ms-transform: rotate(45deg);
													transform: rotate(45deg);
													display: block;
													position: absolute;
												}
											}
											.indicators-options-list {
												&:nth-child(5) {
													border-bottom: 1px solid black;
												}
											}
										}
									}
								}

								&::after {
									content: '';
									position: absolute;
									right: 12px;
									top: 12px;
									border: solid black;
									border-width: 0 3px 3px 0;
									display: inline-block;
									padding: 3px;
									transform: rotate(45deg);
								}
							}

							.tooltips:hover .tooltiptext {
								visibility: visible;
							}
						}
						.navbar-header {
							margin: 4px 15px 0 0;

							.search-input {
								width: 220px;
								height: 35px;
								outline: none;
								border-radius: 8px;
								padding: 0px 18px 0px 32px;
								border: 1px solid var(--greyscale-10);
								background-color: var(--greyscale-10);
							}
							.search-result-wrapper {
								background-color: var(--greyscale-0);
								width: 250px;
								box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
								color: black;
								z-index: 10;
								height: 250px;
								overflow-y: auto;
								border-radius: 5px;
								top: calc(100% + 4px);

								.search-result {
									.list {
										.item {
											padding: 10px 20px;
											border-bottom: 1.5px solid var(--greyscale-10);

											&:hover {
												background-color: var(--greyscale-10);
											}
										}
									}
								}
							}
							.date-label {
								max-width: 200px;
								width: 100%;
							}

							.search-icon {
								top: 8px;
								left: 8px;
							}
						}
					}

					.charts-item {
						overflow: hidden !important;
						padding-top: 10px !important;

						.react-stockchart {
							width: 100% !important;
							height: 100%;
						}
					}
				}
			}
			.indicators-container {
				width: 99%;
				margin: 8px 0;
				.indicator-main {
					margin-right: 40px;
					background-color: var(--greyscale-0);
					border-radius: 5px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					border: 1px solid #84878b;
					h3 {
						font-size: 9px;
						font-weight: 500;
						line-height: 14px;
						padding: 4px 8px;
						margin: 0;
					}
					.cross-icon {
						padding: 10px;
					}
				}
			}
		}

		.add-widget {
			margin: 20px 0 30px 0;
		}
	}
}

.chart-settings-popup {
	overflow-y: hidden !important;
	.modal-dialog {
		width: auto;
		max-width: 1176px;
		.modal-content {
			border-radius: 22px;
			.modal-body {
				.advance-settings-footer {
					border-top: 1px solid var(--primary-color);
				}
				.empty-div {
					height: 50px;
				}
				.advance-setting-main {
					min-height: 128px;
					max-height: 423px;
					overflow: auto;
					.table-responsive {
						overflow-x: unset !important;
						min-width: 1024px;
						.table {
							thead {
								position: sticky;
								top: 0;
								background: #ffffff;
								z-index: 1;
							}
							margin-bottom: 0 !important;
							.chart-details-row {
								.delete-icon--container {
									padding: 6px;
									background-color: var(--red-light-color);
									border-radius: 6px;
									border: 1px solid var(--error-color);
								}
								.color-pallet--main {
									bottom: -17px;
									background-color: var(--greyscale-0);
									padding: 9px;
									border-radius: 6px;
									display: grid;
									grid-template-columns: auto auto auto;
									gap: 6px;
									z-index: 2;
									left: 37px;
								}
							}
							.indicator-row {
								.indicator-div {
									.indicator-color {
										border-radius: 6px 0 0 6px;
									}
									.indicator-title {
										border-radius: 0 6px 6px 0;
										margin: 5px 25px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.right-axis {
	.react-stockchart-text-background {
		transform: translateX(-10px);
	}
}

.left-right-axis {
	.right {
		.react-stockchart-text-background {
			transform: translateX(-10px);
		}
	}
	.left {
		.react-stockchart-text-background {
			transform: translateX(0px);
		}
	}
}

.duplicate-chart-tooltip {
	max-width: 220px !important;
	z-index: 999;
	padding: 10px !important;
	text-align: center;
	background-color: var(--greyscale-0) !important;
	color: var(--primary-color) !important;
	box-shadow: (0px 4px 13px rgba(0, 0, 0, 0.15));
	font-size: 12px !important;
	line-height: 17px !important;
	font-weight: 500 !important;
	word-wrap: break-word;
}

.close-table-icon {
	svg {
		transform: rotate(180deg);
	}
}

.input-question-mark {
	top: 0;
	right: 10px;
}

.table-collapse-btn {
	border-radius: 8px;
	padding: 5px;
	right: -14px;
	background-color: var(--greyscale-0);
	border: 1px solid var(--greyscale-50);
}

.overlay {
	background-color: #17161a;
	height: 100vh;
	left: 0;
	opacity: 0.6;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 0;
	margin-top: 75px;
}

.chart-forecast-indicator__mapper {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: serif;
	font-size: 3em;
	display: flex;
	align-items: center;
	justify-content: center;
}

.forecast-radio {
	display: none;
}
.form-control-forecast {
	display: inline-flex;
	align-items: center;
	color: black;

	.unlock-icon {
		position: absolute;
		transform: rotate(45deg);
		top: 2px;
		left: 4px;
	}
}

.chart-pdfModule-indicator__mapper {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: serif;
	font-size: 3em;
	display: flex;
	align-items: center;
	justify-content: center;
}

.pdfModule-radio {
	display: none;
}
.form-control-pdfModule {
	display: inline-flex;
	align-items: center;
	color: black;
}

.circle {
	position: relative;
	height: 26px;
	width: 26px;
	display: inline-block;
	margin-right: 10px;
	border-radius: 50%;
	border-left: 0 !important;
	border-bottom: 0 !important;
	transform: rotate(314deg);

	.inner-circle {
		height: 18px;
		width: 18px;
		display: block;
		position: absolute;
		left: 13px;
		top: 11px;
		transform: translate(-50%, -50%);
		border-radius: 50%;
		opacity: 0.5;
	}
}
