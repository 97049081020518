.lds-facebook div {
	background: #3ab394 !important;
	/* background: #2f4050 !important; */
}

.spinner {
	display: flex;
	justify-content: center;
	align-items: center;
}

.spinner-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 28px;
}

.loader {
	border: 2px solid grey; /* Light grey */
	border-top: 2px solid #ffffff; /* Blue */
	border-radius: 50%;
	width: 20px;
	height: 20px;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

/* ---------------------ripple loader ------------------- */
.lds-spinner {
	color: official;
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-spinner div {
	transform-origin: 27px 30px;
	animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
	content: ' ';
	display: block;
	position: absolute;
	top: 9px;
	left: 37px;
	width: 3px;
	height: 13px;
	border-radius: 20%;
	background: #081d34;
}
.lds-spinner div:nth-child(1) {
	transform: rotate(0deg);
	animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
	transform: rotate(30deg);
	animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
	transform: rotate(60deg);
	animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
	transform: rotate(90deg);
	animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
	transform: rotate(120deg);
	animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
	transform: rotate(150deg);
	animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
	transform: rotate(180deg);
	animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
	transform: rotate(210deg);
	animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
	transform: rotate(240deg);
	animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
	transform: rotate(270deg);
	animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
	transform: rotate(300deg);
	animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
	transform: rotate(330deg);
	animation-delay: 0s;
}
@keyframes lds-spinner {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
  }
  
  /*-------------dotted loader-------------*/

  .loader {
	width: 48px;
	height: 48px;
	border: 3px dotted transparent;
	border-style: solid solid dotted dotted;
	border-radius: 50%;
	display: inline-block;
	position: relative;
	box-sizing: border-box;
	animation: rotation 2s linear infinite;
  }
  .loader::after {
	content: '';  
	box-sizing: border-box;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	border: 3px dotted #081d34;
	border-style: solid solid dotted;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	animation: rotationBack 1s linear infinite;
	transform-origin: center center;
  }


.white-loader ::after{
	border: 3px dotted white;

}
	  
  @keyframes rotation {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  } 
  @keyframes rotationBack {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(-360deg);
	}
  } 
  
