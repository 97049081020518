@import 'assets/styles/_variable.scss';

.iframe-container {
	height: 380px;
	max-width: 1020px;
	background-color: #f3f4f4;
	padding-left: 23px;
	padding-right: 23px;
	padding-top: 6px;
	padding-bottom: 3px;
	box-sizing: border-box;
	border-radius: 29px;

	.matflixx-bme-icon__wrapper {
		width: 100%;
		height: 37px;

		svg {
			width: 237px;
			height: 37px;
		}
	}
	.register-btn {
		width: 170px;
	}

	.favourite-item__wrapper {
		width: 100%;
		height: 245px;
		margin-right: 30px;
		pointer-events: none;
		box-shadow: 0px 4px 4px 0px #00000040;

		.chart-wrapper {
			overflow: hidden;
		}
		.card-header {
			font-size: 14px !important;
		}
		.card-items {
			overflow: hidden;

			.swiper-pagination {
				display: none !important;
			}
			.news-card__wrapper {
				.news-image {
					height: 70px !important;
				}
				.news-data__wrapper {
					.news-data {
						h1 {
							font-size: 10px !important;
							line-height: 13px;
						}
					}
					.star-icon__wrapper {
						display: none;
					}
				}
				.news-article-date {
					font-size: 8px !important;
					line-height: 10px !important;
				}
			}
			.card-content {
				overflow: hidden;
				height: calc(100% - 21px);
				.tender-card__wrapper {
					height: 30px !important;
					.tender-data__wrapper {
						.news-data {
							h1 {
								margin: 0 !important;
							}
						}
					}
				}
				.medium-news-card__wrapper {
					height: 48px;
					justify-content: start;
					.news-image {
						width: 28px;
						height: 28px;
					}
					.news-data__wrapper {
						width: 100%;
						.star-icon__wrapper {
							display: none;
						}
						.news-data {
							width: 100%;
							h1 {
								font-size: 10px !important;
								line-height: 13px;
							}
						}
					}
				}
			}
			.card-bottom {
				height: 21px;
				h1 {
					font-size: 10px !important;
				}
			}
			.items {
				height: 50px !important;
				padding-top: 0;
				padding-bottom: 0;
				.item-title {
					h1 {
						font-size: 10px !important;
						margin: 0 !important;
					}
				}
				.item-values {
					.value {
						.title {
							font-size: 8px !important;
							margin: 0 !important;
						}
						.item {
							font-size: 10px !important;
							line-height: 10px !important;
							.lock-icon {
								width: 10px;
								height: 10px;
							}
						}
					}
				}
			}
		}
	}
	.favourite-item__wrapper:last-child {
		margin-right: 0px !important;
	}

	.swiper-arrow__wrapper {
		position: absolute;
		top: 0;
		bottom: 0;
		.slick-disabled {
			pointer-events: none;
			fill: var(--greyscale-30);
		}
	}

	.right-arrow {
		right: -16px;
	}

	.left-arrow {
		left: -16px;
	}

	.swiper {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		height: calc(100% - 32px);
		overflow: hidden;
		padding-bottom: 7px;
		padding-right: 7px;
		padding-left: 7px;

		.slick-prev {
			position: absolute;
			bottom: 5.54px;
			right: 98px;
			padding: 0;
			width: 20px;
			height: 20px;
		}

		.slick-next {
			position: absolute;
			right: 18px;
			bottom: 5.54px;
			padding: 0;
			width: 20px;
			height: 20px;
		}

		.swiper-wrapper {
			display: flex;
			height: 100%;
			position: relative;

			.swiper-slide {
				.news-card__wrapper {
					.news-image {
						height: 142px;
						margin-bottom: 16px;
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
							border-radius: 8px;
						}
					}

					.news-data__wrapper {
						width: 100%;
						.star-icon__wrapper {
							.star-icon {
								width: 20px;
								height: 20px;
							}
						}
					}
					.news-article-date {
						p {
							font-size: 16px;
							font-weight: 400;
							line-height: 22px;
						}
					}
				}
			}
		}
		.swiper-pagination {
			height: 8px;
			bottom: -7px !important;
			z-index: 0;
			display: block !important;
			width: 100% !important;
			display: flex;
			justify-content: center;
			align-items: center;

			.swiper-pagination-bullet {
				width: 8px;
				height: 4px;
				background-color: var(--greyscale-30);
				margin-left: 0 !important;

				&.swiper-pagination-bullet-active-next,
				&.swiper-pagination-bullet-active-prev {
					background-color: white;
				}

				&.swiper-pagination-bullet-active-prev {
					transform: unset;
					margin: 0px 4px 0px 0px;
					margin-left: 0 !important;
				}

				&.swiper-pagination-bullet-active {
					background-color: #081d34 !important;
					margin: 0px 4px 0px 0px;
					margin-left: 0 !important;
				}

				&.swiper-pagination-bullet-active-main {
					background-color: var(--greyscale-30);
					margin-left: 0 !important;
					opacity: 1 !important;
				}
			}
		}

		.slick-dots {
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			bottom: 14px;
			right: 46px;
			margin: 0;

			li {
				margin-right: 4px;
				background-color: var(--greyscale-30);

				&.slick-active {
					background-color: var(--primary-color);
				}
				.ft-slick__dots--custom {
					width: 8px;
					height: 4px;
				}
			}
		}
	}
}
